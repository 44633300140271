export const getEndPoint = (clientId) => {
  let endPoint = `${process.env.REACT_APP_SA_HOST}/clients/${clientId}/message`;
  return endPoint;
};

export const getInfoSectionURL = (clientId, assistantMode) => {
  let endPoint = `${process.env.REACT_APP_SA_HOST}/clients/${clientId}`;
  if (assistantMode == "faq_chat" || assistantMode == "faq_search") {
    endPoint = `${endPoint}/faq/message`;
  } else if (
    assistantMode == "reviews_chat" ||
    assistantMode == "reviews_search"
  ) {
    endPoint = `${endPoint}/reviews/message`;
  }
  return endPoint;
};
