// OrderDetails.js
import React from "react";
import { RedirectPageIcon } from "./icons/RedirectPageIcon";
// import { FaChevronRight } from 'react-icons/fa'; // Import Chevron Right icon from react-icons

const OrderDetails = ({ order, trackColor, trackTextColor }) => {

  console.log("order",order);
  const orderDetailsStyle = {
    backgroundColor: "#ffffff",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    margin: "20px auto",
    maxWidth: "600px",
  };

  const headerStyle = {
    color: "#333333",
    fontSize: "1.5rem",
    marginBottom: "20px",
  };

  const infoRowStyle = {
    display: "flex",
    flexDirection: "column",
    marginBottom: "15px",
  };

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  };

  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: trackColor,
    color: trackTextColor,
    border: "none",
    borderRadius: "4px",
    padding: "12px 24px",
    fontSize: "1rem",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    textTransform: "uppercase",
  };

  const iconStyle = {
    marginLeft: "5px",
  };

  const FaChevronRight = ">";

  return (
    <div style={orderDetailsStyle}>
      <div className="order-info">
        <div style={infoRowStyle}>
          <span>
            <strong>Status :</strong> {order.Status}
          </span>
          {order["Tracking Number"] !== "N/A" && order["Tracking Number"] && (
            <span>
              <strong>Tracking Number :</strong> {order["Tracking Number"]}
            </span>
          )}
          <span>
            <strong>Shipping Address:</strong>{" "}
            {order["Shipping Address"]?.address1},{" "}
            {order["Shipping Address"]?.city},{" "}
            {order["Shipping Address"]?.country}
          </span>
        </div>
      </div>
      <div style={buttonContainerStyle}>
        {order["Tracking URL"] !== "N/A" && order["Tracking URL"] ? (
          <a href={order["Tracking URL"]} target="_blank" rel="noreferrer">
            <button style={buttonStyle}>
              Track Order <RedirectPageIcon />
            </button>
          </a>
        ) : (
          <a href={order["Order Page URL"]} target="_blank" rel="noreferrer">
            <button style={buttonStyle}>
              Check Order <RedirectPageIcon />
            </button>
          </a>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
