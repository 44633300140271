import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import Tracker from "../components/Tracker";

const VerificationContext = createContext();

export const useVerification = () => {
  return useContext(VerificationContext);
};

export const VerificationProvider = ({ children }) => {
  const [verified, setVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [clientId, setClientId] = useState("");
  const [assistantMode, setAssistantMode] = useState(1);
  const [settings, setSettings] = useState({});
  const [botConfig, setBotConfig] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [clientDomain, setClientDomain] = useState("");
  const [user_id, setUser_id] = useState("");
  const [productContext, setProductContext] = useState({
    id: null,
    name: null,
    price: null,
  });
  const [isTestEvent, setIsTestEvent] = useState(false);
  const testDomain = [
    "localhost",
    "127.0.0.1",
    "hamptonsun.discoverist.ai",
    "sechey.discoverist.ai",
    "tc.discoverist.ai",
    "demo.discoverist.ai",
  ];

  const fetchAndSetSettings = async (clientId) => {
    try {
      const url = `${process.env.REACT_APP_INGESTOR_HOST}/${clientId}/settings`;
      const settings = await axios
        .get(url, {
          headers: { "x-api-key": process.env.REACT_APP_DISCOVERIST_API_KEY },
        })
        .then((response) => response.data)
        .catch((error) => {
          console.error("Error:", error);
          setErrorMessage("Some Error Occured");
        });
    
      setSettings(settings?.data?.shopping_assistant);
      return true;
    } catch (error) {
      console.error(error);
    }
  };

  const verifyUser = async (clientId) => {
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const shopUrl = urlParams?.get("shop_Url")?.split("/")[2];
    // const domains = settings?.domains.whitelist ?? [];
    // if (testDomain.includes(shopUrl) || domains?.includes(shopUrl)) {
    //   setIsTestEvent(true);
      setVerified(true);
    //   return;
    // } else {
    //   setVerified(false);
    //   setErrorMessage("User not verified.");
    // }
  };

  const initializeTracker = (clientId) => {
    if (typeof discoveristTracker !== "undefined") {
      window.discoveristTracker.initializeTracker(
        clientId,
        "1Z93CvAUmG3LfQ5yKUgqs7PHLLZBCNjR2uq21Y8p"
      );
    } else {
      console.error("discoveristTracker is not defined");
    }
  };

  const sendCustomEvent = (eventName, eventData) => {
    if (typeof discoveristTracker !== "undefined") {
      window.discoveristTracker.sendEvent(eventName, {
        ...eventData,
        user_id: user_id,
      });
    } else {
    
    }
  };

  // const sendCustomEvent = (eventName, eventData) => {
  //   if (typeof discoveristTracker !== "undefined") {
  //     window.discoveristTracker.sendEvent(eventName, eventData);
  //   } else {
  //     console.error("discoveristTracker is not defined");
  //   }
  // };

  useEffect(() => {
    async function load() {
      const queryString = window.location.search;
      const pathParts = window.location.pathname.split("/");
      let clientId = pathParts[3] === "clients" ? pathParts[4] : null;
      const urlParams = new URLSearchParams(queryString);
      const getAssistantMode = urlParams.get("assitant_mode");
      setAssistantMode(getAssistantMode);
      const getUser_id = urlParams.get("user_id");
      setUser_id(getUser_id);
      if (!clientId) {
        clientId = urlParams.get("clientid");
        setClientId(clientId);
        initializeTracker(clientId);
      }
      if (urlParams.get("productid") && urlParams.get("productname")) {
        const id = urlParams.get("productid");
        const name = urlParams.get("productname");
        const price = urlParams.get("productprice");
        setProductContext({ id, name, price });
      }
      await fetchAndSetSettings(clientId);
    }
    load();
  }, []);

  useEffect(() => {
    if (settings && clientId) {
      verifyUser(clientId);
      setIsLoading(false);
    }
  }, [settings]);

  if (isLoading) return <></>;

  return (
    <VerificationContext.Provider
      value={{
        verified,
        errorMessage,
        verifyUser,
        settings,
        productContext,
        setProductContext,
        clientId,
        clientDomain,
        sendCustomEvent,
        isLoading,
        isTestEvent,
        assistantMode,
        user_id,
      }}
    >
      {settings ? children : "Error"}
      {settings?.client_id && <Tracker clientId={settings.client_id} />}
    </VerificationContext.Provider>
  );
};
