import React, { useEffect } from "react";

const Tracker = ({ clientId }) => {
  const runTracker = () => {
  
    (function (window, document) {
      if (window.discoveristTracker) {
        return;
      }
      const discoveristTracker = {
        version: "1.1",
        clientId,
        initialized: false,
        eventsQueue: [],
        sendEvent: function (eventName, eventData) {
          if (!this.initialized) {
            console.error("Discoverist tracker not initialized. Call initializeTracker() first.");
            this.eventsQueue.push({ name: eventName, data: eventData });
            return;
          }
          eventData = eventData || {};
        
          const data = this.getPixelSrc(eventName, eventData);
          fetch(data.url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.REACT_APP_DISCOVERIST_API_KEY,
            },
            body: JSON.stringify(data.eventData),
          })
            .then((response) => {
            
              return response.json();
            })
            .then((data) => {
              const sessionId = data.session_id;
              const storedSessionId = localStorage.getItem("session_id");
              if (sessionId && sessionId !== storedSessionId) {
                localStorage.setItem("session_id", sessionId);
              }
            })
            .catch((error) => {
              console.error("Error sending event with POST method:", error);
            });
        },
        getPixelSrc: function (eventName, eventData = {}) {
          const baseUrl = `${process.env.REACT_APP_CLICKSTREAM_HOST}/clients/${clientId}/collect`;
          const defaultEventData = {
            pageUrl: window.location.href,
            timestamp: new Date().toISOString(),
          };
          const mergedEventData = { ...defaultEventData, ...eventData };
          const urlWithQuery = baseUrl;
          const pixelData = {
            url: urlWithQuery,
            eventData: mergedEventData,
          };
        
          return pixelData;
        },
        initializeTracker: function () {
          if (this.initialized) {
            console.warn("Discoverist tracker already initialized.");
            return;
          }
        
          const pixel = document.createElement("img");
          pixel.id = "discoveristTrackingPixel";
          pixel.style.display = "none"; // Hide the pixel
          document.body.appendChild(pixel);
          this.initialized = true;
          // Process any events in the queue
          while (this.eventsQueue.length > 0) {
            const queuedEvent = this.eventsQueue.shift();
            this.sendEvent(queuedEvent.name, queuedEvent.data);
          }
        },
      };
      window.discoveristTracker = discoveristTracker;
    })(window, document);
  };

  useEffect(() => {
    runTracker(clientId);
  }, [clientId]);

  useEffect(() => {
    if (clientId && window.discoveristTracker) {
      window.discoveristTracker.initializeTracker(clientId, process.env.REACT_APP_DISCOVERIST_API_KEY);
    }
  }, [clientId, window.discoveristTracker]);

  return <div />;
};

export default Tracker;
