import React, { useEffect, useState } from "react";
import styles from "../styles/faqSearch.module.css";
import axios from "axios";
import { useVerification } from "../context/VerificationContext";
export const InfoSectionSearch = (props) => {
  const {
    messages,
    question,
    isGeneratingChat,
    isChatReady,
    sendChatReq,
    clearChatHistory,
    setContext,
    OGquestion,
    setOGquestion,
    removeContext,
    isContextSet,
    prodContext,
    handleDockButtonClose,
    add2cart,
    toggleChatBox,
    userProfile,
    setUserProfile,
    serverError,
    isUserChatSent,
    setIsUserChatSent,
    assistantMode,
  } = props;
  const { clientId } = useVerification();
  const getProdData = JSON.parse(localStorage.getItem("reviewsProduct"));

  const [aiSummary, setAiSummary] = useState("");

  useEffect(() => {
    (async function(){try {
      const url = `${process.env.REACT_APP_RECOMMENDAION_API}/${clientId}/review_summary/${getProdData.id}`;
      const settings = await axios
        .get(url, {
          headers: { "x-api-key": process.env.REACT_APP_DISCOVERIST_API_KEY },
        })
        .then((response) => {
          setAiSummary(response.data.results);
      
        })
        .catch((error) => {
          console.error("Error:", error);
          // setErrorMessage("Some Error Occured");
        });
   
      return true;
    } catch (error) {
      console.error(error);
    }})();
  }, []);

  return (
    <div className={styles.firstContainer}>
      <div className={styles.faqSearch}>
        <div className={styles.faqSearchContainer}>
          <button
            disabled={isGeneratingChat}
            type="submit"
            className={styles.faqSearchButton}
            onClick={() => sendChatReq(OGquestion)}
          >
            {isGeneratingChat ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              <i class="fa-solid fa-magnifying-glass"></i>
            )}
          </button>
          <input
            type="text"
            className={styles.faqSearchInput}
            placeholder={
              assistantMode == "reviews_search"
                ? "Search Cutomer Reviews"
                : "Ask me a question?"
            }
            disabled={!isChatReady}
            value={OGquestion || question}
            onChange={(e) => setOGquestion(e.target.value)}
            autoCapitalize="sentences"
            autoComplete="off"
            autoFocus={true}
            spellCheck={true}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendChatReq(OGquestion);
              }
            }}
            style={{ border: "none" }}
          />
        </div>
      </div>

      <div className={styles.secondContainer}>
        {messages.length > 0 && (
          <div className={styles.faqQuestionAnswer}>
            <div className={styles.faqQuestion} contenteditable="false">
              {/* {messages.length == 0 && (
            <span className={styles.placeHolder}>
              {" "}
              {/* Your Question Will Be Displayed Here.....{" "} 
            </span>
            */}

              <span>{messages[messages.length - 1].question}</span>
            </div>
            <div className={styles.faqAnswer}>
              {" "}
              {/* {messages.length == 0 && (
            <span className={styles.placeHolder}>
              {" "}
              {/* AI Answer Will Be Displayed Here.....{" "} 
            </span>
            */}
              <div>
                <p> AI Answer:</p>
                <span> {messages[messages.length - 1].answer}</span>
              </div>
            </div>
          </div>
        )}
        {assistantMode == "reviews_search" && aiSummary && (
          <div className={styles.reviewSummaryContainer}>
            {" "}
            <h4 className={styles.reviewSummaryHeading}>
              AI Review Summary
            </h4>{" "}
            <div className={styles.reviewSummaryText}>{aiSummary}</div>{" "}
          </div>
        )}
      </div>
    </div>
  );
};
