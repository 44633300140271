import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Logo from "../assets/images/chatLogo2.png";
import "../styles/ChatbotForm.css";
import "../styles/custom.css";
import "../styles/main_styles.css";
import "../styles/responsive.css";
import "../styles/ToggleButton.css";
import {
  BOT_HISTORY,
  CLOSED_BUTTON_CLICKED,
  CONNECTION_ESTABLISHED,
  OPEN_BUTTON_CLICKED,
  CLIENT_INIT_DATA,
  // SESSION_STARTED,
  EVENT_SOURCE,
  // OPEN_CLICKED,
  BOT_OPEN,
  FAQ_OPEN_BUTTON_CLICKED,
  REVIEWS_OPEN_BUTTON_CLICKED,
  FAQ_CLOSED_BUTTON_CLICKED,
  REVIEWS_CLOSED_BUTTON_CLICKED,
  FAQ_BOT_HISTORY,
  REVIEWS_BOT_HISTORY,
} from "../constants";
import { useVerification } from "../context/VerificationContext";
import {
  getEndPoint,
  getInfoSectionURL,
} from "../utils/clientIdExchange";
import useBotSettings from "../customHooks/useBotSettings";
import { InfoSectionSearch } from "./InfoSectionSearch";
import { InfoSectionChat } from "./InfoSectionChat";

const EMPTY_PRODUCT_CONTEXT = { id: "", text: "none", price: "" };

const ChatComponent = () => {
  const {
    botConfig,
    clientId,
    sendCustomEvent,
    settings,
    isTestEvent,
    productContext,
    setProductContext,
    assistantMode,
    user_id,
  } = useVerification();
  // const settings = useBotSettings(botConfig);
  const { icon_height, icon_shape, icon_color, icon_image } =
    settings.bot_styles?.icon_style;

  const [isChatReady, setIsChatReady] = useState(false);
  const [ws, setWs] = useState(false);
  const [messages, setMessages] = useState(() => {
    if (assistantMode == "faq_search" || assistantMode == "reviews_search") {
      return [];
    }
    if (assistantMode == "faq_chat") {
      try {
        const storedHistory = localStorage.getItem(FAQ_BOT_HISTORY);
        return JSON.parse(storedHistory) || [];
      } catch (error) {
        console.error("Error parsing bot history from localStorage:", error);
        return [];
      }
    }
    if (assistantMode == "reviews_chat") {
      try {
        const storedHistory = localStorage.getItem(REVIEWS_BOT_HISTORY);
        return JSON.parse(storedHistory) || [];
      } catch (error) {
        console.error("Error parsing bot history from localStorage:", error);
        return [];
      }
    }
    try {
      const storedHistory = localStorage.getItem(BOT_HISTORY);
      return JSON.parse(storedHistory) || [];
    } catch (error) {
      console.error("Error parsing bot history from localStorage:", error);
      return [];
    }
  });
  const [question, setQuestion] = useState("");
  const [OGquestion, setOGquestion] = useState("");
  const [isGeneratingChat, setIsGeneratingChat] = useState(false);
  const [prodContext, setProdContext] = useState({
    ...EMPTY_PRODUCT_CONTEXT,
    id: productContext.id,
    text: productContext.name,
    price: productContext.price,
  });
  const [isContextSet, setIsContextSet] = useState(() =>
    prodContext.text && !["none", "{Product Name}"].includes(prodContext.text)
      ? true
      : false
  );
  const [toggleChatBox, setToggleChatBox] = useState({
    hideChatBox: "none",
    hideToggleButton: "flex",
  });
  const [userProfile, setUserProfile] = useState(() => {
    try {
      const userProfile = localStorage.getItem("userProfile");
      return JSON.parse(userProfile) || {};
    } catch (error) {
      console.error("Error parsing User Profile from localStorage:", error);
      return {};
    }
  });
  const [isUserChatSent, setIsUserChatSent] = useState(false);
  const isAssistantModeReviews =
    assistantMode === "reviews_chat" || assistantMode == "reviews_search"
      ? true
      : false;

  let origin = window.self === window.top ? document.URL : document.referrer;
  if (!origin) {
    origin = document.URL;
  }

  useEffect(() => {
   
  }, [isContextSet, prodContext]);

  origin = origin.substring(0, origin.length - 1);
  function establishWebSocketConnection() {

    const wsUrl = assistantMode
      ? getInfoSectionURL(clientId, assistantMode)
      : getEndPoint(clientId);
  
    const ws = new WebSocket(wsUrl);
    setWs(ws);
    ws.onopen = () => {
     
      setIsChatReady(true);
      window.parent.postMessage(
        {
          id: CONNECTION_ESTABLISHED.id,
          type: CONNECTION_ESTABLISHED.type,
          discoveristData: {
            product: {},
            hrefData: {},
            review: {},
            search: {},
          },
        },
        "*"
      );
      window.addEventListener("message", handleIframeEvent);
    };
    ws.onerror = (error) => {
   
      if (ws && ws.readyState === WebSocket.OPEN) {
        establishWebSocketConnection();
      }
    };
    ws.onclose = (event) => {
      if (event.wasClean) {
      
      } else {
     
      }
    };
    ws.onmessage = (event) => {
    
    };
  }

  const handleIframeEvent = (event) => {
    const eventType = event.data?.type;
    const eventUser = event.data.data?.user;
    const eventProduct = event.data.data?.product;
  
    if (eventType === CLIENT_INIT_DATA.type || isAssistantModeReviews) {
      if (Object.keys(userProfile).length !== 0) {
        if (userProfile.id !== eventUser.id) {
          setUserProfile(eventUser);
        }
      }
  
      if (eventProduct.name && eventProduct.name !== "{Product Name}") {
     

        setProdContext({
          id: eventProduct.id,
          text: eventProduct.name ? eventProduct.name : "none",
          price: eventProduct.price ? eventProduct.price : "none",
        });
        setIsContextSet(
          eventProduct.name
            ? eventProduct.name &&
              !["none", "{Product Name}"].includes(eventProduct.name)
              ? true
              : false
            : false
        );
        // if (assistantMode) {
        try {
          localStorage.setItem(
            "reviewsProduct",
            JSON.stringify({
              id: eventProduct.id,
              text: eventProduct.name ? eventProduct.name : "none",
              price: eventProduct.price ? eventProduct.price : "none",
            })
          );
        } catch (error) {
          console.error(
            "Error parsing reviewsProduct to localStorage:",
            error
          );
        }

        // }
      }
    }
  };

  useEffect(() => {
    if (clientId) {
      establishWebSocketConnection();
    }
    // return () => {
    //   if (ws) ws.close();
    // };
  }, [clientId]);

  const saveChatHistory = () => {
    if (assistantMode == "faq_search" || assistantMode == "reviews_search") {
      return;
    }
    if (assistantMode == "faq_chat") {
      try {
        localStorage.setItem(FAQ_BOT_HISTORY, JSON.stringify(messages));
      } catch (error) {
        console.error(
          "Error parsing faq bot history to localStorage:",
          error
        );
      }
      return;
    }
    if (assistantMode == "reviews_chat") {
      try {
        localStorage.setItem(REVIEWS_BOT_HISTORY, JSON.stringify(messages));
      } catch (error) {
        console.error(
          "Error parsing reviews bot history to localStorage:",
          error
        );
      }
      return;
    }

    try {
      localStorage.setItem(BOT_HISTORY, JSON.stringify(messages));
    } catch (error) {
      console.error("Error saving bot history to localStorage:", error);
    }
  };

  useEffect(() => {
    saveChatHistory();
    try {
      localStorage.setItem("userProfile", JSON.stringify(userProfile));
    } catch (error) {
      console.error("Error saving UserProfile to localStorage:", error);
    }
  }, [messages, userProfile]);

  const clearChatHistory = () => {
    // removeContext();
    ws.close();
    setIsChatReady(false);
    const newWs = new WebSocket(
      assistantMode
        ? getInfoSectionURL(clientId, assistantMode)
        : getEndPoint(clientId)
    );
    setWs(newWs);
    newWs.onopen = () => {
   
      setIsChatReady(true);
    };
    setMessages([]);
    setQuestion("");
    setIsGeneratingChat(false);

    if (assistantMode == "faq_search" || assistantMode == "reviews_search") {
      return;
    }
    if (assistantMode == "faq_chat") {
      try {
        localStorage.removeItem(FAQ_BOT_HISTORY);
      } catch (error) {
        console.error("Error removing faq item from localStorage:", error);
      }
      return;
    }
    if (assistantMode == "reviews_chat") {
      try {
        localStorage.removeItem(REVIEWS_BOT_HISTORY);
      } catch (error) {
        console.error("Error removing reviews item from localStorage:", error);
      }
      return;
    }

    try {
      localStorage.removeItem(BOT_HISTORY);
    } catch (error) {
      console.error("Error removing item from localStorage:", error);
    }
  };

  const sendChatReq = (userQuery) => {
    setQuestion(userQuery);
    setOGquestion("");
    setIsUserChatSent(true);
  };

  useEffect(() => {
    let reviewsPayload;
    if (isAssistantModeReviews) {
      try {
        reviewsPayload = JSON.parse(localStorage.getItem("reviewsProduct"));
      } catch (error) {
        console.error("Error parsing User Profile from localStorage:", error);
        return {};
      }
    }
    if (question.trim().length > 1 && question.trim().length < 200) {

      let payload = {
        product: {
          product_id: isAssistantModeReviews
            ? reviewsPayload.id
            : prodContext.id,
          product_name: isAssistantModeReviews
            ? reviewsPayload.text
            : prodContext.text,
          product_price: isAssistantModeReviews
            ? reviewsPayload.price
            : prodContext.price,
        },
      };
   
      if (isContextSet || isAssistantModeReviews) {
        const payloadText = `${prodContext.text} ${productContext.price ? `costing ${productContext.price}` : ""
          }`.trim();
        payload = {
          ...payload,
          question:
            `This is my question about the ${payloadText}: ${question}`.trim(),
          isContextSet: isContextSet,
          prodId: isAssistantModeReviews ? "" : prodContext.id,
          prodContext: isAssistantModeReviews ? "" : payloadText,
          chatHistory: messages,
          userProfile: userProfile,
          user_id: user_id,
        };
      
      } else {
        setProdContext(EMPTY_PRODUCT_CONTEXT);
        payload = {
          ...payload,
          question: question,
          isContextSet: isContextSet,
          prodId: isAssistantModeReviews ? "" : prodContext.id,
          prodContext: isAssistantModeReviews ? "" : prodContext.text ? prodContext.text : "none",
          chatHistory: messages,
          userProfile: userProfile,
          user_id: user_id,
        };
     
      }
    
      setIsGeneratingChat(true);
      ws.send(JSON.stringify(payload));

      const taskToDoAfterResponse = () => {
        setQuestion("");
        setOGquestion("");
        setIsGeneratingChat(false);
        ws.removeEventListener("message", onMessage);
        saveChatHistory();
      };

      const onMessage = (event) => {
        const response = JSON.parse(event?.data)?.result;

        console.log("response",response);
     
        if (response === "Server down, please try again later") {
          if (ws && ws.readyState === WebSocket.OPEN) {
       
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                question: question,
                answer: "Server is down. Reconnecting...",
                products: [],
                context: isAssistantModeReviews ? "" : prodContext.id ? prodContext : "none",
              },
            ]);
            taskToDoAfterResponse();
            // check if close  ? reconnect
            establishWebSocketConnection();
          }
          return;
        }

        const prodsList = response["result"][1] ?? [];
        console.log("response.result",response.result)
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            question: question,
            answer: response?.result[0].includes("Order Number") ? response?.result : response?.result[0] ?? [],
            products: prodsList,
            context: isAssistantModeReviews ? "" : prodContext.id ? prodContext : "none",
          },
        ]);
        taskToDoAfterResponse();
        // ws.removeEventListener("message", onMessage);
        saveChatHistory();
      };
      ws.addEventListener("message", onMessage);
      return () => {
        ws.removeEventListener("message", onMessage);
      };
    }
  }, [question]);

  const setContext = (product) => {
  
    let prod = EMPTY_PRODUCT_CONTEXT;

    const allProducts = messages?.reduce((prods, msg) => {
      if (Array.isArray(msg.products))
        msg.products.forEach((prod) => prods.push(prod));
      return prods;
    }, []);
    prod = allProducts.find((prod) => prod.text === product.text);
    let payloadText = `${prod?.text} costing $${product.price}`;
   
    setProdContext({ ...EMPTY_PRODUCT_CONTEXT, text: product.text ?? "none" });
  };

  const removeContext = () => {
    setProdContext(EMPTY_PRODUCT_CONTEXT);
  };

  const handleDockButtonClose = () => {
    setToggleChatBox({ hideChatBox: "none", hideToggleButton: "flex" });
    if (assistantMode == "faq_chat" || assistantMode == "faq_search") {
      window.parent.postMessage(
        {
          id: FAQ_CLOSED_BUTTON_CLICKED.id,
          type: FAQ_CLOSED_BUTTON_CLICKED.type,
          discoveristData: {
            product: {},
            hrefData: {},
            review: {},
            search: {},
          },
        },
        origin
      );
    } else if (
      assistantMode === "reviews_chat" ||
      assistantMode == "reviews_search"
    ) {
      window.parent.postMessage(
        {
          id: REVIEWS_CLOSED_BUTTON_CLICKED.id,
          type: REVIEWS_CLOSED_BUTTON_CLICKED.type,
          discoveristData: {
            product: {},
            hrefData: {},
            review: {},
            search: {},
          },
        },
        origin
      );
    } else {
      window.parent.postMessage(
        {
          id: CLOSED_BUTTON_CLICKED.id,
          type: CLOSED_BUTTON_CLICKED.type,
          discoveristData: {
            product: {},
            hrefData: {},
            review: {},
            search: {},
          },
        },
        "*"
      );
    }
  };

  const handleDockButtonOpen = () => {
    setToggleChatBox({
      hideChatBox: "block",
      hideToggleButton: "none",
    });
    sendCustomEvent(BOT_OPEN, {
      event_source: EVENT_SOURCE,
      is_testevent: isTestEvent,
    });
    if (assistantMode == "faq_chat" || assistantMode == "faq_search") {
      window.parent.postMessage(
        {
          id: FAQ_OPEN_BUTTON_CLICKED.id,
          type: FAQ_OPEN_BUTTON_CLICKED.type,
          discoveristData: {
            product: {},
            hrefData: {},
            review: {},
            search: {},
          },
        },
        origin
      );
    } else if (
      assistantMode === "reviews_chat" ||
      assistantMode == "reviews_search"
    ) {
      window.parent.postMessage(
        {
          id: REVIEWS_OPEN_BUTTON_CLICKED.id,
          type: REVIEWS_OPEN_BUTTON_CLICKED.type,
          discoveristData: {
            product: {},
            hrefData: {},
            review: {},
            search: {},
          },
        },
        origin
      );
    } else {
      window.parent.postMessage(
        {
          id: OPEN_BUTTON_CLICKED.id,
          type: OPEN_BUTTON_CLICKED.type,
          discoveristData: {
            product: {},
            hrefData: {},
            review: {},
            search: {},
          },
        },
        "*"
      );
    }
  };
  const add2cart = (prod) => {
    // alert(`The ${prod} have been added to your cart!`);
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {assistantMode == "faq_search" || assistantMode == "reviews_search" ? (
        <InfoSectionSearch
          messages={messages}
          question={question}
          isGeneratingChat={isGeneratingChat}
          isChatReady={isChatReady}
          sendChatReq={sendChatReq}
          clearChatHistory={clearChatHistory}
          setQuestion={setQuestion}
          // setContext={setContext}
          OGquestion={OGquestion}
          setOGquestion={setOGquestion}
          removeContext={removeContext}
          isContextSet={isContextSet}
          prodContext={prodContext}
          handleDockButtonClose={handleDockButtonClose}
          add2cart={add2cart}
          toggleChatBox={toggleChatBox.hideChatBox}
          userProfile={userProfile}
          setUserProfile={setUserProfile}
          setMessages={setMessages}
          botConfig={botConfig}
          // pdpName={pdpName}
          isUserChatSent={isUserChatSent}
          setIsUserChatSent={setIsUserChatSent}
          assistantMode={assistantMode}
        />
      ) : assistantMode == "faq_chat" || assistantMode == "reviews_chat" ? (
        <>
          {" "}
          <InfoSectionChat
            messages={messages}
            question={question}
            isGeneratingChat={isGeneratingChat}
            isChatReady={isChatReady}
            sendChatReq={sendChatReq}
            clearChatHistory={clearChatHistory}
            setQuestion={setQuestion}
            setContext={setContext}
            OGquestion={OGquestion}
            setOGquestion={setOGquestion}
            removeContext={removeContext}
            isContextSet={isContextSet}
            prodContext={prodContext}
            handleDockButtonClose={handleDockButtonClose}
            add2cart={add2cart}
            toggleChatBox={toggleChatBox.hideChatBox}
            userProfile={userProfile}
            setUserProfile={setUserProfile}
            setMessages={setMessages}
            // botConfig={botConfig}
            // pdpName={pdpName}
            isUserChatSent={isUserChatSent}
            setIsUserChatSent={setIsUserChatSent}
          />
        </>
      ) : (
        <>
          <Modal
            messages={messages}
            question={question}
            isGeneratingChat={isGeneratingChat}
            isChatReady={isChatReady}
            sendChatReq={sendChatReq}
            clearChatHistory={clearChatHistory}
            setQuestion={setQuestion}
            setContext={setContext}
            OGquestion={OGquestion}
            setOGquestion={setOGquestion}
            removeContext={removeContext}
            isContextSet={isContextSet}
            prodContext={prodContext}
            handleDockButtonClose={handleDockButtonClose}
            add2cart={add2cart}
            toggleChatBox={toggleChatBox.hideChatBox}
            userProfile={userProfile}
            setUserProfile={setUserProfile}
            setMessages={setMessages}
            botConfig={botConfig}
            // pdpName={pdpName}
            isUserChatSent={isUserChatSent}
            setIsUserChatSent={setIsUserChatSent}
          />
        </>
      )}

      {assistantMode !== "faq_search" && assistantMode !== "reviews_search" && (
        <div
          id="toggleButtons"
          style={{
            display: toggleChatBox.hideToggleButton,
            width: "100%",
            height: "100%",
            borderRadius: icon_shape == "round" ? "50%" : "10px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: icon_color ?? "transparent",
            boxShadow: "none",
            cursor: "auto",
          }}
          onClick={handleDockButtonOpen}
        >
          {icon_image ? (
            <img
              src={icon_image ? icon_image : Logo}
              alt="Toggle Button"
              style={{
                cursor: "pointer",
                height:
                  botConfig?.bot_styles?.icon_style.icon_size_height ?? "100px",
                width:
                  botConfig?.bot_styles?.icon_style.icon_size_width ?? "100px",
                borderRadius:
                  botConfig?.bot_styles?.icon_style.icon_shape == "Round"
                    ? "50%"
                    : "10px",
              }}
            />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              style={{
                width: icon_height ?? "100px",
                height: icon_height ?? "100px",
                cursor: "pointer",
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z"
              />
            </svg>
          )}
        </div>
      )}
    </div>
  );
};
export default ChatComponent;
